import React from 'react'
import "./last.scss"

const LastSection = () => {
    return (
        <>
            <section className="lastsec">
                <img src="\assets\last-bg.png" alt="img" className='img-fluid last-bg' />
                <div className="custom-container">
                    <div className="inner-content">
                        <h6>Rank Up in the TOMI Army</h6>
                        <h4>Earn Points, Progress Through Ranks, and Enjoy Being Part of Our Community</h4>
                        <p>As you earn more points, you can progress through ranks and levels in the tomi army. We're always here if you need any additional help. Welcome and enjoy being part of our community!</p>
                        <a href="https://app.tomiarmy.com/" target="_blank">Join the Army <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                            <path d="M9.75 9.20176L15.9 3.05176" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.4999 6.05166V2.45166H12.8999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.25 2.45166H6.75C3 2.45166 1.5 3.95166 1.5 7.70166V12.2017C1.5 15.9517 3 17.4517 6.75 17.4517H11.25C15 17.4517 16.5 15.9517 16.5 12.2017V10.7017" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LastSection
