
import React from "react";
import "./banner.scss";

const Banner = () => {
  return (
    <>
      <section className="main-banner">
        <img src="\assets\banner-img.png" alt="img" className="img-fluid w-100 banner-img desktop-img" />
        <img src="\assets\banner-bg-mbl.png" alt="img" className="img-fluid w-100 banner-img d-none mobile-img" />
        <div className="custom-container">
          <div className="main-content">
            <h6>welcome to tomi army</h6>
            <h2>join the Tomi Revolution!</h2>
            <p>tomi's vision is to provide the world with a decentralized, alternative internet to give people access to a platform free of censorship and surveillance, an internet built by the best web2 & web3 tech and governed by you, its future user through DAO governance.</p>
            <a href="https://app.tomiarmy.com/" target="_blank">Join TOMI Army <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <path d="M9.75 8.75001L15.9 2.60001" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M16.4999 5.6V2H12.8999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.25 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V10.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg></a>
          </div>
          <div className="bottom-cards">
            <div className="single-card">
              <img src="\assets\completetask.png" alt="img" className="img-fluid" />
              <h6>GETTING STARTED</h6>
              <p>Install Metamask extension. Make sure you have at least 1 TOMI token in your wallet.</p>
            </div>
            <div className="single-card">
              <img src="\assets\joinsquad.png" alt="img" className="img-fluid" />
              <h6>JOIN THE COMMUNITY</h6>
              <p>Connect with the community on Discord: <a href="https://discord.gg/tomipioneers" style={{color: "#ff0083"}}>https://discord.gg/tomipioneers</a></p>
            </div>
            <div className="single-card">
              <img src="\assets\earnpoints.png" alt="img" className="img-fluid" />
              <h6>JOIN OUR TELEGRAM</h6>
              <p>Join the Telegram channel: <a href="https://t.me/tomiarmypublic" style={{color: "#ff0083"}}>https://t.me/tomiarmypublic</a></p>
            </div>
            <div className="single-card">
              <img src="\assets\gettomirewards.png" alt="img" className="img-fluid" />
              <h6>JOIN THE ARMY</h6>
              <p>Click Join the Army and create your profile.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
