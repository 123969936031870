import React from 'react'
import "./twice.scss"

const Twice = () => {
  return (
    <>
      <section className="twice-section twice-section1" id='about'>
        <img src="\assets\glory-banner.png" alt="img" className='img-fluid glory-banner desktop-img' />
        <img src="\assets\glory-mobile1.png" alt="img" className='img-fluid glory-banner d-none mobile-img w-100' />
          <div className="custom-container">
          <div className="row">
                <div className="col-xl-6 col-12 p-0 ordersm-2">
                    <div className="wrapper-img">
                        <img src="\assets\roadtoglory.svg" alt="img" className='img-fluid desktop-img' />
                    </div>
                </div>
                <div className="col-xl-6 col-12 p-0 m-auto ordersm-1">
                    <div className="inner-content">
                        <h6>Building the TOMI Army</h6>
                        <h4>Earn Points & Convert THEM to $TOMI TOKENS</h4>
                        <p>As a community, we have an amazing opportunity to build an army for the project - an army that will promote tomi in communities and directly reward users. By completing more simple or complex tasks, you can earn points that can be later converted into TOMI tokens, something that benefits everyone involved!</p>
                    </div>
                </div>
            </div>
          </div>
       
      </section>
      <section className="twice-section twice-section2">
        <img src="\assets\glory-banner1.png" alt="img" className='img-fluid glory-banner desktop-img' />
        <img src="\assets\glory-mobile2.png" alt="img" className='img-fluid glory-banner d-none mobile-img w-100' />
          <div className="custom-container">
          <div className="row">
                <div className="col-xl-6 col-12 p-0 m-auto">
                    <div className="inner-content">
                        <h6>Connect, Participate, Earn</h6>
                        <h4>Start Earning Points and Tokens by Engaging in Community Activities</h4>
                        <p>Once you've connected your wallet, you'll enter the system and can start earning points and tokens by participating in community activities.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-12 p-0">
                    <div className="wrapper-img">
                        <img src="\assets\roadtoglory1.svg" alt="img" className='img-fluid' />
                    </div>
                </div>
            </div>
          </div>
       
      </section>
    </>
  )
}

export default Twice
