import React, { useEffect, useState } from "react";
import "./privacypolicy.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";


const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section className="mainprivacypolicy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-10 col-12 p-0 m-auto">
              <Link to="/" className="sdsddsx">
                <button type="button" class="btnnav">
                  <img src="/newmmm/arrow-left.svg" alt="" />
                  <p className="btn-tag">Back to home</p>
                </button>
              </Link>
              <div className="privacypolicy">
                <div className="upper-head">
                  <h1>tomi Privacy Policy</h1>
                </div>
                <div className="topprivacypolicy">
                  <p className="privacypolicytoppara">
                    tomi ("tomi" or "We") is committed to maintaining the
                    privacy of its users ("You" or "User"). tomi adheres to
                    strict data protection practices and implements data
                    protection policies and procedures to assure that the
                    privacy of its users will be adequately protected. The
                    following information describes how tomi collects and
                    processes information about you when you use our Services,
                    Wallet and Website (as defined below).
                  </p>
                  <h6 className="privacypolicytophead">
                    Our Privacy Policy explains:
                  </h6>
                  <ol>
                    <li className="privacypolicytopheadlist">
                      What information we collect and why we collect it.
                    </li>
                    <li className="privacypolicytopheadlist">
                      How we use that information.
                    </li>
                    <li className="privacypolicytopheadlist">
                      How we secure your information.
                    </li>
                    <li className="privacypolicytopheadlist">
                      Your rights with regard to the collection of such
                      information.
                    </li>
                  </ol>
                </div>
                <h5 className="privacypolicymainhead">GENERAL</h5>
                <p className="privacypolicymainpara">
                  tomi develops hardware and software, and provides services
                  pertaining to decentralized cloud and additional services,
                  including our Pioneers community club and a digital wallet
                  (the "Wallet") which enables users to purchase, sale and trade
                  cryptocurrencies, tokens, non-fungible tokens and other
                  digital assets (“Digital Assets”). The services provided by
                  tomi are specified in tomi’s Website and terms of use
                  ("Services"). tomi's website provides information on the
                  Wallet, Digital Assets and Services and enables users to
                  contact tomi when they wish to do so ("Website").
                </p>
                <h5 className="privacypolicymainhead">
                  USING OUR WEBSITE AND WALLET VIA YOUR DEVICE
                </h5>
                <p className="privacypolicymainpara">
                  Users can access our Wallet and Website on a device
                  ("Device"). A Device is any computer used to access the Wallet
                  or Website, including without limitation a desktop, laptop,
                  mobile phone, tablet, or other consumer electronic device.
                  This Privacy Policy governs your access to the Wallet and
                  Website, regardless of how you access it, and by using our
                  Wallet and Website you consent to the collection, transfer,
                  processing, storage, disclosure and other uses described in
                  this Privacy Policy
                </p>
                <h5 className="privacypolicymainhead">
                  COLLECTING INFORMATION
                </h5>
                <p className="privacypolicymainpara marginbot">
                  Please note that the scope of this Privacy Policy is limited
                  only to information collected by tomi through your use of its
                  Wallet and Website. Some information may be automatically
                  collected, and some is collected when you interact with our
                  Wallet and Website. The type of information that may be
                  automatically collected is non-personal information, which
                  includes your session durations, the content you accessed on
                  the Wallet and Website, you digital wallet address, device ID,
                  uniqueID, time and date of first insallment of the Wallet
                  information about your Device and your internet connection and
                  the frequency and scope of your use of the Wallet and Website.
                </p>
                <p className="privacypolicymainpara">
                  Information from which you can be personally identified may
                  also be collected, including but not limited to your name,
                  telephone number, username, social networks accounts
                  (including but not limited to twitter and discord), email
                  address, wallet address, IP, and the location of the Device
                  through which you used the Wallet and Website (hereinafter:
                  "Personal Information") Your Personal Information will be
                  collected, without limitation, as described below:
                </p>
                <h6 className="privacypolicylowerlisthead">
                  a. When you register to use the Website
                </h6>
                <p className="privacypolicymainpara">
                  If you register to create a tomi account ("Account"), we will
                  collect your Personal Information such as your name, username,
                  telephone number, social networks accounts (including but not
                  limited to twitter and discord), IP address and email address
                  to enable you to use the Website. Alternatively, we may
                  provide you the options to choose to register via your Single
                  Sign-On account (“SSO”) such as Apple, Facebook or Google
                  accounts (if available on the Website), if you choose so, tomi
                  will be provided with access to information these SSO services
                  obtain about you, and all in accordance with your Apple,
                  Facebook or Google accounts' privacy settings. The information
                  we collect in this manner typically includes your username,
                  userID, email address and public information from your profile
                  on these services.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  b. When you create a tomi Profile
                </h6>
                <p className="privacypolicymainpara">
                  When you create an Account, we will create a profile for you.
                  For that purpose we will collect your name, username, email
                  address, any files and content you choose to upload and
                  content you wish to share or sell. Users can choose to have
                  their Accounts private or public. Please note, that if your
                  Account was made public, or you choose to share your
                  information uploaded to tomi on a social media account, some
                  Personal Information you submitted will be made available to
                  other users of the Wallet and Website and users of the
                  relevant social media account. Some information included in a
                  private Account, such as a your name, username, photo, account
                  value, number of followers and number of following, mutual
                  followers, will also be visible to all users of the Wallet and
                  Website
                </p>
                <h6 className="privacypolicylowerlisthead">
                  c. Connecting your wallet
                </h6>
                <p className="privacypolicymainpara">
                  If you choose to connect your cryptocurrency wallet via
                  platforms such as Metamask, WalletConnect, Coinbase or others,
                  in order to buy Pioneer club memberships, buy TOMI tokens or
                  to otherwise interact with the Website, tomi will be provided
                  with access to your cryptocurrency wallet login credentials,
                  and all in accordance with your Metamask, WalletConnect,
                  Coinbase or any other cryptocurrency wallet privacy settings.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  d. Buying TOMI tokens or Pioneers club memberships
                </h6>
                <p className="privacypolicymainpara">
                  If you choose to buy TOMI tokens or{" "}
                  <a className="pinklink" href="">
                    Pioneers club memberships
                  </a>{" "}
                  , we will collect your payment information if you make a
                  transaction via the Website. tomi uses third party payment
                  processors to assure that your payment details are secured in
                  accordance with the highest security standard.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  e. Register to Receive Marketing Materials
                </h6>
                <p className="privacypolicymainpara">
                  If you wish to receive marketing materials from tomi, we will
                  collect your email address and your consent for sending you
                  marketing materials.
                </p>
                <h6 className="privacypolicylowerlisthead">f. Contacting Us</h6>
                <p className="privacypolicymainpara">
                  If you wish to contact us for support regarding our Wallet,
                  Services, this Privacy Policy or any other matter, we will
                  collect your name, email address and the content you submitted
                  to facilitate your inquiry.
                </p>
                <h5 className="privacypolicymainhead">USE OF INFORMATION:</h5>
                <p className="privacypolicymainpara">
                  We use the Personal Information we collect from you for a
                  range of different business purposes according to different
                  legal bases of processing. We may use or process your Personal
                  Information for the following purposes. One or more purposes
                  may apply simultaneously.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  Providing the Requested Services:
                </h6>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    We collect your Personal Information to provide you with the
                    Services you contracted to receive. Such collection of
                    information will enable us to provide you with technical and
                    professional assistance, with regard to the functionality of
                    the Website and Services or to any inquiry you submit to us
                    through it.
                  </li>
                </ol>
                <p className="privacypolicymainpara">
                  We process the Personal Information where it is necessary for
                  the adequate performance of the contract regarding your use of
                  our Website.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  Improvement and Development of the Services:
                </h6>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    We collect Personal Information to improve and develop our
                    Website and Services and understand feedback on tomi’s
                    Services and to help provide more information on the use of
                    those Services via our Website
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    We collect Personal Information for the ongoing review and
                    improvement of the information provided on our Website to
                    ensure they are user friendly .
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    We collect Personal Information to improve the management
                    and administration of our business and maintain compliancy
                    with our internal policies and procedures.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    We conduct surveys and research, test features in
                    development, and analyze the information we have to evaluate
                    and improve our Website and Services, develop new features,
                    and conduct audits and troubleshooting activities .
                  </li>
                </ol>
                <p className="privacypolicymainpara">
                  We process this information in light of our legitimate
                  interest in improving the Website and Services, to allow our
                  users to have the best experience.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  Maintain a Safe and Secure Environment
                </h6>
                <p className="privacypolicymainpara marginbot">
                  We may use your information to detect and prevent fraud, abuse
                  and security incidents in the following ways;
                </p>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    Verify and authenticate your identity and prevent
                    unauthorized or illegal activity.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Enhance the safety and security of our Services and Website.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Conduct security investigations and risk assessments
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Prevent or take action against activities that are, or may
                    be, in breach of our terms of service or applicable law .
                  </li>
                </ol>
                <p className="privacypolicymainpara">
                  We process this information in light of our legitimate
                  interest in improving our Services, Wallet and Website by
                  enabling our users to browse in a secure environment.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  Personalize Content, Advertising and Marketing
                </h6>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    If you have used tomi’s Services in the past, we have a
                    legitimate business interest for matching the data we
                    collect with other data we had already collected .
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    This enables us to understand your needs and interests,
                    optimize the content we send you, provide you with relevant
                    notifications and make the Website and Services more
                    suitable and relevant to your needs.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    This also enables us to improve your experience on the
                    Website by providing you with personalized content,
                    recommendations, and features.
                  </li>
                </ol>
                <p className="privacypolicymainpara">
                  We process this information in light of our legitimate
                  interest to personalize your experience and customize our
                  content.
                </p>
                <h5 className="privacypolicymainhead">
                  DISCLOSURE OF INFORMATION AND TRANSFER OF DATA
                </h5>
                <p className="privacypolicymainpara marginbot">
                  Except as otherwise provided in this Privacy Policy, we
                  reasonably attempt to ensure that we never intentionally
                  disclose any of your Personal Information, to any third party
                  without having received your permission, except as provided
                  for herein or otherwise as permitted or required under law.
                </p>
                <p className="privacypolicymainpara marginbot">
                  We may share your Personal Information with our affiliates,
                  subsidiaries, partners or any third party companies and
                  individuals to facilitate our Services or any portion thereof,
                  such as cryptocurrency wallet platforms and our partners
                  utilizing the Website, Wallet and Services, as well as
                  marketing, storage, data management or maintenance services.
                  We will share your Personal Information, to the extent
                  required, with cryptocurrency wallet platforms who will
                  process your TOMI token purchases, in case you wish to place a
                  bid. We will also share your Personal Information with third
                  party payment processors who will process your payment details
                  if you choose to purchase Pioneer club memberships (without
                  the use of a cryptocurrency wallet). We may also share your
                  information with analytics service providers for analytics
                  purposes. Such analytics service providers set their own
                  cookies or other identifiers on your Device, through which
                  they can collect information about your usage of our Website.
                  This helps us compile aggregated statistics about the
                  effectiveness of our Website and Services.
                </p>
                <p className="privacypolicymainpara marginbot">
                  The above mentioned third parties may be located in countries
                  other than your own, and we may send them information we
                  receive. When such third parties process your Personal
                  Information on our behalf, we will assure that they comply
                  with obligations similar to those which are set forth in this
                  Privacy Policy. We will also assure that they will abide by
                  our data privacy and security requirements, and will be
                  allowed to use the Personal Information solely for the
                  purposes we set. We will transfer your Personal Information
                  while using appropriate and suitable safeguards, while using a
                  variety of legal mechanisms, including contracts, to ensure
                  your rights and protections travel with your data.
                </p>
                <p className="privacypolicymainpara">
                  We may also transfer your information, including Personal
                  Information, in connection with a corporate merger,
                  consolidation, the sale of related assets or corporate
                  division or other fundamental corporate changes. Furthermore,
                  information about you may also be released in order to comply
                  with any valid legal inquiry or process such as a search
                  warrant, subpoena, statute or court order. We will also
                  release specific information in special cases, such as if you
                  use the Services, Wallet or Website to perform an unlawful act
                  or omission or take any act or omission that may damage tomi,
                  its property and goodwill, or if there is an attempted breach
                  of the security of the Website, Wallet and/or Services or a
                  physical or property threat to you or others. With respect to
                  our data protection practices, you have the right to file a
                  complaint to any relevant supervisory data protection
                  authority.
                </p>
                <h5 className="privacypolicymainhead">YOUR RIGHTS</h5>
                <p className="privacypolicymainpara marginbot">
                  You have the right at any time to request to access or modify
                  your information. To exercise these options, please contact us
                  at{" "}
                  <a href="" className="pinklink">
                    privacy@tomi.com
                  </a>{" "}
                  . In some jurisdictions, in particular those located within
                  the European Union (the "EU") or within the European Economic
                  Area (the "EEA"), you may be afforded specific rights
                  regarding your Personal Information. Subject to such
                  eligibility, you may have the following rights to:
                </p>

                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    Request a rectification of your Personal Information where
                    the information we hold about you is incorrect or
                    incomplete.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Object to the processing of your Personal Information for
                    direct marketing purposes.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Object to the processing of your Personal Information where
                    our legal basis for that processing is that such processing
                    is necessary for our legitimate interests
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Object to an automated decision-making (including profiling)
                    in certain circumstances.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Request the erasure of your Personal Information in certain
                    circumstances, such as where processing is no longer
                    necessary for the purpose it was originally collected for,
                    and there is no compelling reason for us to continue to
                    process or store it;
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Receive your Personal Information, or ask us to transfer it
                    to another organization that you have provided to us, which
                    we process by automated means, where our processing is
                    either based on your consent or is necessary for the
                    performance of a contract with you
                  </li>
                </ol>
                <p className="privacypolicymainpara">
                  If you wish to file a request regarding any of the above, you
                  may contact us at:{" "}
                  <a href="" className="pinklink">
                    privacy@tomi.com.
                  </a>
                </p>
                <h5 className="privacypolicymainhead">COOKIES</h5>
                <p className="privacypolicymainpara">
                  We may use "cookies" and/or other technologies or files
                  (collectively, "cookies") to identify how users make use of
                  our Website and Services. This aggregated tracking information
                  may be used to help us improve and enhance the Website and
                  Services’ experience for all of our users. In addition,
                  cookies are used for adjusting the Website and Services to
                  your personal preferences. Cookies contain information such as
                  the pages you visited, the length of time you stayed on the
                  Website, the location from which you accessed the Website and
                  more. If you would prefer not to have cookies stored on your
                  Device, you may modify your browser settings to reject most
                  cookies, or manually remove cookies that have been placed on
                  your computer. However, by rejecting the cookies, you may be
                  unable to fully access the offerings on our Services, Wallet
                  and Website. To find out more about cookies, visit{" "}
                  <a href="" className="pinklink">
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <h5 className="privacypolicymainhead">OPT IN OR OPT OUT</h5>
                <p className="privacypolicymainpara">
                  If, at any time, you would like to stop using our Service or
                  opt out of a feature, or stop receiving relevant materials
                  from us, you may notify us by writing to{" "}
                  <a href="" className="pinklink">
                    privacy@tomi.com
                  </a>
                  . You should be aware, however, that it is not always possible
                  to completely remove or modify information in our databases
                  and servers, although we will always make reasonable efforts
                  to do so upon your request .
                </p>
                <h5 className="privacypolicymainhead">
                  LINKS TO OTHER WEBSITES
                </h5>
                <p className="privacypolicymainpara">
                  The Website may provide links to other websites. Please be
                  aware that these other websites are not covered by our Privacy
                  Policy. This Privacy Policy does not cover the information
                  practices exercised by other providers of products or
                  services, advertisers or other websites, companies or
                  individuals, which are not owned or controlled by tomi. We
                  suggest that when linking to another website, you always read
                  that website’s privacy policy before volunteering any
                  personally identifiable information.
                </p>
                <h5 className="privacypolicymainhead">DATA SECURITY</h5>
                <p className="privacypolicymainpara">
                  We deploy industry standard, or better measures to ensure the
                  security, confidentiality, integrity and availability of
                  Personal Information we process. We maintain physical,
                  technical and administrative safeguards, and test and update
                  these periodically. We endeavor to restrict access to Personal
                  Information on a ‘need to know’ basis for the provision of
                  Services to you. In the event of a security breach, we will
                  take all reasonable action to minimize any harm, including
                  with regard to notifications. Although we will do our best to
                  protect Personal Information, we cannot guarantee the security
                  of data transmitted to Website, and transmission is at the
                  users own risk.
                </p>
                <h5 className="privacypolicymainhead">DATA RETENTION</h5>
                <p className="privacypolicymainpara">
                  Generally, tomi does not retain information longer than
                  necessary to provide its Services and for its reasonable
                  business and lawful needs. If you withdraw your consent to us
                  processing your Personal Information, we will erase your
                  Personal Information from our systems, unless the information
                  is required for tomi to establish, exercise or defend against
                  legal claims or it is necessary for the performance of the
                  requested Services.
                </p>
                <h5 className="privacypolicymainhead">
                  CALIFORNIA ONLINE PRIVACY PROTECTION ACT
                </h5>
                <p className="privacypolicymainpara">
                  CalOPPA requires commercial websites and online services to
                  post a privacy policy. The law's reach stretches well beyond
                  California to require any person or company in the United
                  States (and conceivably the world) that operates websites
                  collecting Personally Identifiable Information from California
                  consumers, to post a conspicuous privacy policy on its website
                  stating exactly the information being collected and those
                  individuals or companies with whom it is being shared. See
                  more at :{" "}
                  <a href="" className="pinklink">
                    http://consumercal.org/california-online-privacyprotection-act-caloppa/#sthash.0FdRbT51.dpuf.
                  </a>
                </p>
                <h6 className="privacypolicylowerlisthead">
                  According to CalOPPA, we agree to the following:
                </h6>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    Once this Privacy Policy is created, we will add a link to
                    it on the first significant page after entering our Website.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Our Privacy Policy link includes the word 'Privacy' and can
                    easily be found on the Website .
                  </li>
                  <li className="privacypolicytopheadlist">
                    You can request to change your Personal Information by
                    emailing us.
                  </li>
                </ol>
                <h5 className="privacypolicymainhead">
                  CALIFORNIA PRIVACY RIGHTS
                </h5>
                <p className="privacypolicymainpara marginbot">
                  The California Consumer Privacy Act of 2018 ("CCPA") permits
                  users who are California residents to request to exercise
                  certain rights. If you are a California resident, the CCPA
                  grants you the right to request certain information about our
                  practices with respect to your Personal Information. In
                  particular, you can request to receive information on the
                  following:
                </p>

                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    The categories and specific pieces of your Personal
                    Information that we have collected.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    The categories of sources from which we collected your
                    Personal Information
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    The business or commercial purposes for which we collected
                    your Personal Information.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    The categories of third parties with which we shared your
                    Personal Information.
                  </li>
                </ol>
                <p className="privacypolicymainpara marginbot">
                  You can rest assure that we do not sell your Personal
                  Information. If you choose to exercise your rights, we will
                  not charge you different prices or provide different quality
                  of our Services, unless those differences are related to your
                  provision of your Personal Information. Please note that you
                  must verify your identity and request before further action is
                  taken. As a part of this process, government identification
                  may be required. Moreover, you may designate an authorized
                  agent to make a request on your behalf . We endeavor to
                  respond to a verifiable consumer request within 45 days of its
                  receipt. If we require more time (up to 90 days), we will
                  inform you of the reason and extension period in writing. Any
                  disclosures we provide, will only cover the 12 month period
                  preceding your verifiable request's receipt. If, for some
                  reason, we cannot reply within such time frame, our response
                  will include an explanation for our inability to comply. If
                  you wish to exercise your CCPA rights, please contact us at:{" "}
                  <a href="" className="pinklink">
                    privacy@tomi.com
                  </a>
                  .
                </p>
                <p className="privacypolicymainpara marginbot">
                  We will not discriminate against you for exercising any of
                  your CCPA rights. Unless permitted by the CCPA, we will not:
                </p>

                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    Deny you goods or services.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Charge you different prices or rates for goods or services,
                    including through granting discounts or other benefits, or
                    imposing penalties
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Provide you with a different level or quality of goods or
                    services
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Suggest that you may receive a different price or rate for
                    goods or services or a different level or quality of goods
                    or services.
                  </li>
                </ol>
                <h5 className="privacypolicymainhead">CAN SPAM ACT</h5>
                <p className="privacypolicymainpara">
                  The CAN-SPAM Act is a Federal US law that sets the rules for
                  commercial email, establishes requirements for commercial
                  messages, gives recipients the right to have emails stopped
                  from being sent to them, and spells out tough penalties for
                  violations.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  To be in accordance with CANSPAM, we agree to the following:
                </h6>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    Not use false or misleading subjects or email addresses
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Identify the commercial message sent to you as an
                    advertisement when required.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Include the physical address of our business or site
                    headquarters
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Monitor third-party email marketing services for compliance,
                    if one is used
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Honor opt-out/unsubscribe requests quickly
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Allow users to unsubscribe by using the link at the bottom
                    of each email.
                  </li>
                </ol>
                <p className="privacypolicymainpara">
                  If at any time you would like to unsubscribe from receiving
                  future emails, you can email us at{" "}
                  <a href="" className="pinklink">
                    privacy@tomi.com
                  </a>{" "}
                  and we will promptly remove you from ALL correspondence.
                </p>
                <h5 className="privacypolicymainhead">CHILDREN'S PRIVACY</h5>
                <p className="privacypolicymainpara">
                  The Website, Wallet and Services are not intended for children
                  under the age of 18. We do not, knowingly or intentionally,
                  collect information about children who are under 18 years of
                  age.
                </p>
                <h6 className="privacypolicylowerlisthead">
                  IF YOU ARE UNDER THE AGE OF 18 YOU MAY NOT USE THE WEBSITE OR
                  CONSUME AND SERVICES
                </h6>
                <h5 className="privacypolicymainhead">
                  QUESTIONS REGARDING OUR PRIVACY POLICY
                </h5>
                <p className="privacypolicymainpara">
                  If you have any questions regarding this Privacy Policy or the
                  practices described above, you are always welcome to contact
                  us at{" "}
                  <a href="" className="pinklink">
                    privacy@tomi.com
                  </a>
                  .
                </p>
                <h5 className="privacypolicymainhead">
                  REVISIONS AND MODIFICATIONS TO OUR PRIVACY POLICY
                </h5>
                <p className="privacypolicymainpara">
                  We reserve the right to revise, amend, or modify this Privacy
                  Policy at any time. When changing the policy, we will update
                  this posting accordingly. Please review this Privacy Policy
                  often so that you will remain updated regarding our current
                  policies
                </p>
                <h5 className="privacypolicymainhead">
                  GOVERNING LAW AND JURISDICTION
                </h5>
                <p className="privacypolicymainpara marginbot">
                  This Privacy Policy will be governed and interpreted pursuant
                  to the laws of the Switzerland without giving effect to its
                  choice of law rules. You expressly agree that the exclusive
                  jurisdiction for any claim or action arising out of or
                  relating to this Privacy Policy shall be to the competent
                  courts in Canton of Zug, Switzerland, to the exclusion of any
                  other jurisdiction.
                </p>
                <p className="privacypolicymainpara">
                  This page was updated on June 2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacypolicy;
