// import logo from './logo.svg';
import '../../App.scss';
import React, { useState, useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';
import Twice from './TwiceSection/Twice';
import LastSection from './LastSection/LastSection';



function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
    <Navbar />
      <Banner />
      <Twice />
      <LastSection />
    </>
  );
}

export default Landing;