import "./navbar.scss";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-lg p-0">
              <a class="navbar-brand" href="/"><img src="\logo.svg" alt="img" className="img-fluid" /></a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <img src="\bar.svg" alt="img" className="img-fluid" />
              </button>
              <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#top">Home</a>
                  </li>
                  {/* <li class="nav-item">
                    <a class="nav-link" href="#">Features</a>
                  </li> */}
                  <li class="nav-item">
                    <a class="nav-link" href="#about">About</a>
                  </li>
                </ul>
                <a href="https://app.tomiarmy.com/" target="_blank" className="common-btn">Join the Army <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M9.75 8.25001L15.9 2.10001" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.4999 5.1V1.5H12.8999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg></a>
              </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Navbar;
